<template>
  <div class="full-page-centered-wrapper">
    <div class="login-container">
      <h2 class="logo">
        <img
          class="logo"
          alt="Tuck Trucks Logo"
          height="24"
          width="200"
          :src="
            this.getCdnFile('/images/logos/tuck-trucks/tt-logo-black.png')
          " />
      </h2>
      <form @submit.prevent="submit">
        <div class="input-wrapper">
          <label for="username">Email Address</label>
          <input
            v-model="loginForm.username"
            id="username"
            name="username"
            type="email"
            required />
        </div>
        <div class="input-wrapper">
          <label for="password">Password</label>
          <div class="password-field full-width">
            <input
              v-model="loginForm.password"
              id="password"
              name="password"
              :type="!showPassword ? 'password' : 'text'"
              required />
            <button
              :onclick="togglePasswordVisibilty"
              type="button"
              aria-label="password visibility toggle button"
              class="icon-only small inline switch-visibility">
              <span
                class="material-icons material-icons-round"
                v-if="!showPassword">
                <Icon :path="mdiEyeOff" :size="18" />
              </span>
              <span
                class="material-icons material-icons-round"
                v-if="showPassword">
                <Icon :path="mdiEye" :size="18" />
              </span>
            </button>
          </div>
        </div>

        <button type="submit" class="full" :disabled="loading">
          <LoadingSpinner v-if="loading" />
          <span class="sign-in" v-else>Sign in</span>
        </button>

        <p v-if="errorMessage != null" class="error">
          {{ errorMessage }}
        </p>
      </form>
    </div>
  </div>
</template>

<script>
  import { Login } from "@/helpers/authentication";
  import { mdiEyeOff, mdiEye } from "@mdi/js";

  export default {
    data() {
      return {
        loginForm: {
          username: "",
          password: "",
        },
        label: "Sign in",
        errorMessage: null,
        loading: false,
        showPassword: false,
        mdiEyeOff,
        mdiEye,
      };
    },

    methods: {
      togglePasswordVisibilty() {
        this.showPassword = !this.showPassword;
      },

      submit() {
        this.loading = true;
        this.label = "";
        this.errorMessage = null;

        Login(this.loginForm.username, this.loginForm.password)
          .then((response) => {
            if (response.authenticated) {
              let serviceId = this.$route.params.serviceId;

              if (serviceId != null) {
                this.$router.push({
                  name: "/service/id",
                  params: {
                    serviceId: serviceId,
                  },
                });
              } else {
                this.$router.push({
                  name: "/services",
                });
              }
            } else {
              this.errorMessage = response.reason;
              this.loading = false;
              this.label = "Sign in";
            }
          })
          .catch((ex) => {
            this.errorMessage =
              "There was a problem during login. Please contact support.";

            window.log.error(
              "An unhandled exception occured during login.",
              ex
            );

            this.loading = false;
            this.label = "Sign in";
          });
      },
    },
  };
</script>

<style lang="scss">
  .login-container {
    max-width: $login_form_max_width;
    min-width: $login_form_min_width;
    width: $login_form_width;
    padding: 1rem 2rem 3rem;
    border-radius: $card_radius;
    box-shadow: $login_form_box_shadow;
    button {
      background-color: $col_beta;
      margin: 0;
      color: #fff;
      @include contained-button(
        $fs: 0.875rem,
        $p: 0.15rem 0.8rem,
        $h: 3rem,
        $w: max-content
      );

      span.sign-in {
        width: 100%;
      }

      &.full {
        width: 100%;
      }
    }
  }
  h2.logo {
    margin: 2rem 0;
    img {
      min-width: $login_logo_img_min_width;
      max-width: $login_logo_img_max_width;
      width: $login_logo_img_width;
      margin: 0 auto;
      display: block;
      height: auto;
    }
  }
  .input-wrapper {
    input {
      clear: left;
      border: 0;
      margin: 1rem 0;
      border: 1px solid #ddd;
      border-radius: $input_radius;
      font-family: sans-serif;
      font-size: 0.875rem;
      &::placeholder {
        font-style: italic;
        color: #aaa;
      }
      &:focus {
        border: 1px solid #2b8e7a;
        outline: 1px solid #2b8e7a;
      }
      &:disabled {
        border: none;
      }
    }
  }

  .password-field {
    position: relative;
    input[name="password"] {
      width: 100%;
      padding-right: 50px;
    }
    button.switch-visibility {
      position: absolute;
      right: 5px;
      color: #aaa;
      z-index: 4;
      top: calc(50% - 19px);
      @include button-base($p: 0.25rem, $h: 2.5rem, $w: 40px);
      background: none !important;
    }
  }

  p.error {
    margin: 0;
    margin-top: 2em;
  }
</style>
